import React from 'react'
import SecondaryLayout from '../layouts/SecondaryLayout'
import Column from '../layouts/Column'
import { graphql } from 'gatsby'

import PageTabSwitch from '../components/PageTabSwitch/PageTabSwitch'
import Title from '../components/Title/Title'
import PagePadding from '../components/PagePadding/PagePadding'
import FeedCardHolderBig from '../components/FeedCardHolderBig/FeedCardHolderBig'
import ReactHtmlParser from 'react-html-parser'
import { isBeforeLastCalendarDate } from '../components/SocialComponents/SocialComponent'
import { getWeek, addWeeks } from 'date-fns'

export default function Calendar(props) {
	const allKalenderEvents = props.data.allWordpressWpKalender.edges
	const cards = allKalenderEvents
		.map((event) => {
			return {
				titles: [event.node.acf.title, event.node.acf.subtitle],
				textContent: [
					ReactHtmlParser(event.node.acf.abstract),
					ReactHtmlParser(event.node.acf.description),
				],
				date: event.node.acf.date,
				image: event.node.acf.image,
			}
		})
		.sort((a, b) => new Date(b.date) - new Date(a.date))
		.filter((item) => isBeforeLastCalendarDate(item))
		.sort((a, b) => new Date(a.date) - new Date(b.date))

	if (cards.length > 12) cards.length = 12

	const allWordpressAcfJaaroverzicht =
		props.data.allWordpressAcfJaaroverzicht?.edges[0]
	const weeks = allWordpressAcfJaaroverzicht.node?.acf?.weken

	function createYearOverview(time) {
		let today = Date.now()
		if (time === 'next') {
			today = addWeeks(today, 1)
		}
		const currentWeek = getWeek(today, [
			{
				weekStartsOn: 6,
			},
		])
		if (weeks.length) {
			const currentWeekData = weeks.filter((week) => {
				const { eind_datum } = week
				const weekNumberOfItem = getWeek(new Date(eind_datum), [
					{
						weekStartsOn: 6,
					},
				])
				return weekNumberOfItem === currentWeek
			})
			return currentWeekData.length ? (
				currentWeekData.map((week) => {
					const { start_datum, eind_datum, text } = week
					return (
						<div>
							<small className="header__season--date">
								{start_datum} - {eind_datum}
							</small>
							<div>{ReactHtmlParser(text)}</div>
						</div>
					)
				})
			) : (
				<div>Weekoverzicht lijkt momenteel leeg te zijn.</div>
			)
		} else {
			return <div>Weekoverzicht lijkt momenteel leeg te zijn.</div>
		}
	}

	return (
		<SecondaryLayout>
			<PagePadding>
				<Column>
					<Title
						heading={1}
						text={'Actueel bij InBalans'}
						wave={true}
					/>
					<Column className={'week__overview'}>
						<div className={'week__overview--item'}>
							<Title
								className={'no-margin'}
								heading={4}
								text={'Deze week'}
								wave={false}
							/>
							{createYearOverview('current')}
						</div>
						<div>
							<Title
								className={'no-margin'}
								heading={4}
								text={'Volgende week'}
								wave={false}
							/>
							{createYearOverview('next')}
						</div>
					</Column>
					<hr />
					<PageTabSwitch
						links={[
							{ name: 'Kalender', to: props.uri },
							{ name: 'Nieuws', to: '/nieuws' },
							{ name: 'FAQ', to: '/faq' },
						]}
					/>
					<Column>
						<FeedCardHolderBig cards={cards} />
					</Column>
				</Column>
				{/* <PaginationNavigation pageContext={props.pageContext} /> */}
			</PagePadding>
		</SecondaryLayout>
	)
}

export const pageQuery = graphql`
	query {
		allWordpressWpKalender {
			edges {
				node {
					acf {
						abstract
						date
						description
						subtitle
						title
						image {
							alt_text
							localFile {
								url
								childImageSharp {
									# Try editing the "maxWidth" value to generate resized images.
									fluid {
										...GatsbyImageSharpFluid_withWebp_tracedSVG
									}
								}
							}
						}
					}
				}
			}
		}
		allWordpressAcfJaaroverzicht {
			edges {
				node {
					acf {
						weken {
							start_datum
							eind_datum
							text
						}
					}
				}
			}
		}
	}
`
